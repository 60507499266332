<script lang="ts" setup>
import { signaturePreviewGateway } from '@msl/signature-preview-gateway-sdk';
import { auth4Sdks } from '@mfl/platform-shell';
import { onMounted, ref, watch } from 'vue';
import { WsToggle, WsInput, WsHtmlViewer } from '@mfl/common-components';
import { OutputModes } from '../enums';

//https://www.figma.com/design/2jMhibMszulphp6mbvUrjH/Editor-new-experience?node-id=1804-3535&node-type=frame&m=dev

const props = defineProps<{
  id?: string;
  employeeId?: string;
  data?: Record<string, unknown>;
  payload?: Record<string, unknown>;
}>();

const scaleToFit = ref<boolean>();
const scaleToPercent = ref<string>('100%');
const content = ref<string>('');

watch(scaleToPercent, (newValue) => {
  if (!newValue) scaleToPercent.value = `${newValue}%`;
});

onMounted(async () => {
  if (props.data || props.id) {
    await signaturePreviewGateway.init(auth4Sdks());

    if (props.data) {
      const res = await signaturePreviewGateway.renderByData({
        data: props.data,
        outputModes: [OutputModes.HTML],
        employeeId: props.employeeId,
        payload: props.payload,
      });
      content.value = res.html ?? '';
    } else {
      const res = await signaturePreviewGateway.renderById({
        id: props.id ?? '',
        outputModes: [OutputModes.HTML],
        employeeId: props.employeeId,
        payload: props.payload,
      });
      content.value = res.html ?? '';
    }
  }
});
</script>

<template>
  <div>
    <!-- preview can accept a scale-value (number) or scale method (actual or fit), not both! -->
    scale To Fit: <WsToggle v-model="scaleToFit" aid="scale_to_fit" />
    <WsInput
      v-model="scaleToPercent"
      :disabled="scaleToFit"
      size="md"
      label="scale to percentage"
      aid="scale"
    />
  </div>

  <div class="ws-editor__signature-preview-container">
    <div class="ws-editor__signature-preview">
      <div class="ws-editor__signature-preview-label">New Message</div>
      <div class="ws-editor__signature-preview-wrap">
        <div class="ws-editor__signature-preview-details">
          <div class="ws-editor__signature-preview-details-to">To</div>
          <div class="ws-editor__signature-preview-details-subject">
            Subject
          </div>
        </div>
        <div class="ws-editor__signature-preview-body">
          <!-- Render the preview component and bind props and events -->
          <WsHtmlViewer
            :scale-to-fit="scaleToFit"
            :scale="Number(scaleToPercent) / 100"
            :content="content"
          />
          <!-- <div v-html="content"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ws-editor__signature-preview-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 70px 100px 100px;
  margin: 0 auto;
  height: calc(
    100vh - 183px
  ); //TODO: currently - hardcoded, need to be set correctly when the editor will be fully constructed.
}

.ws-editor__signature-preview {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  min-height: 250px;
  width: 640px;
  max-height: 600px;
  padding: 0;
  margin: 0 auto;

  &-label {
    border-radius: 6px 6px 0 0;
    padding: 10px;
    font-size: 9px;
    font-weight: 700;
    color: rgb(var(--color-gray-400));
    background-color: rgb(var(--color-primary-300));
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 6px 6px;
    padding: 0 20px 40px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    box-shadow: var(--box-shadow);
    will-change: height;
    background-color: #fff;
  }

  &-details {
    margin-bottom: 10px;
    font-size: 12px;
    color: rgb(var(--color-gray-300));

    &-to,
    &-subject {
      padding: 10px 20px;
      border-bottom: 1px solid rgb(var(--color-gray-200));
    }
  }

  &-body {
    display: flex;
    padding: 10px 0 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #9f9f9f;
    }
    &::-webkit-scrollbar-track {
      background-color: #ccc;
    }
  }
}
</style>
